import { EN, ID } from '../shared/constants/locale';

export const DRIVER_ACCOUNT = {
  [EN]: 'Driver Account',
  [ID]: 'Akun Sopir',
};

export const DRIVER_LIST = {
  [EN]: 'Driver List',
  [ID]: 'Daftar Sopir',
};

export const DRIVER_NAME = {
  [EN]: `Driver's Name`,
  [ID]: 'Nama Sopir',
};

export const DRIVER_DROPDOWN_PLACEHOLDER = {
  [EN]: 'Select or Type here',
  [ID]: 'Pilih atau Ketik di sini',
};

export const LICENSE_PLATE_PLACEHOLDER = {
  [EN]: 'Type here',
  [ID]: 'Ketik di sini',
};

export const DRIVER_BULK_ASSIGN_LABEL = {
  [EN]: 'Assign 1 Driver for all dates',
  [ID]: 'Assign 1 Sopir untuk semua tanggal',
};

export const SAVE_DRIVER_INFO_CHECKBOX_LABEL = {
  [EN]: "Save Driver's Info",
  [ID]: "Simpan Informasi Sopir",
};

export const DRIVER_PHONE_NUMBER = {
  [EN]: `Driver's Phone Number`,
  [ID]: 'Nomor Telepon Sopir',
};

export const DRIVER_LICENSE_NUMBER = {
  [EN]: `Driver's License Number`,
  [ID]: 'No SIM Sopir',
};

export const DRIVER_LICENSE_PLATE = {
  [EN]: `Driver's License Plate`,
  [ID]: 'No Pelat Mobil Supir',
};

export const DRIVER_LICENSE_PLATE_REQUIRED = {
  [EN]: `Driver's License Plate is required`,
  [ID]: 'No Pelat Mobil Supir harus diisi',
};

export const DRIVER_PHOTO = {
  [EN]: `Driver's Photo`,
  [ID]: 'Foto Sopir',
};

export const DRIVER_REWARD = {
  [EN]: 'Driver Reward',
  [ID]: 'Hadiah Sopir',
};

export const DRIVER_INFORMATION = {
  [EN]: 'Driver Information',
  [ID]: 'Informasi Sopir',
};

export const DRIVER_PHOTO_REQUIRED = {
  [EN]: `Driver's photo is required`,
  [ID]: 'Foto sopir harus dimasukkan',
};

export const ADD_NEW_DRIVER = {
  [EN]: 'Add New Driver',
  [ID]: 'Tambah Sopir Baru',
};

export const DRIVER_REWARD_PERIOD_EXPLANATION = {
  [EN]: 'Point shown is not the latest',
  [ID]: 'Poin yang ada dalam periode ini belum yang paling update',
};

export const DRIVER_REWARD_RANK_EXPLANATION = {
  [EN]: 'This is your rank based from the entire market in the specific city',
  [ID]: 'Ini ada peringkat dari semua pasar di kota spesifik',
};

export const DRIVER_REWARD_ELIGIBLE_EXPLANATION = {
  [EN]: 'Eligible status define driver who fits these criteria:',
  [ID]: 'Sopir yang memenuhi syarat harus memiliki kriteria:',
};

export const DRIVER_REWARD_ELIGIBLE_EXPLANATION_DETAIL_1 = {
  [EN]: 'Driver must have at least 5 rides during the month',
  [ID]: 'Sopir harus memiliki paling tidak 5 booking bulan ini',
};

export const DRIVER_REWARD_ELIGIBLE_EXPLANATION_DETAIL_2 = {
  [EN]: 'Driver must have at least 4 points to qualify',
  [ID]: 'Sopir harus memiliki paling sedikit 4 poin untuk memenuhi syarat',
};

export const DRIVER_REWARD_NOT_ELIGIBLE_EXPLANATION = {
  [EN]: 'Driver who are not eligible fits these criteria:',
  [ID]: 'Sopir yang tidak memiliki syarat memiliki kriteria:',
};

export const DRIVER_REWARD_INELIGIBLE_EXPLANATION_DETAIL_1 = {
  [EN]: 'Driver has less than 5 rides during the month',
  [ID]: 'Sopir memiliki kurang dari 5 booking bulan ini',
};

export const DRIVER_REWARD_INELIGIBLE_EXPLANATION_DETAIL_2 = {
  [EN]: 'Driver has less 4 points',
  [ID]: 'Sopir memiliki kurang dari 4 poin',
};

export const DRIVER_REWARD_POINT = {
  [EN]: 'Total Point',
  [ID]: 'Total Point',
};

export const DRIVER_DETAIL = {
  [EN]: 'Driver Detail',
  [ID]: 'Detail Sopir',
};

export const DRIVER_FEEDBACK = {
  [EN]: 'Driver Feedback',
  [ID]: 'Saran Pengemudi',
};

export const DRIVER_ACCOUNT_UPDATE_CONFIRMATION_MESSAGE = {
  [EN]: 'Are you sure want to update these driver(s)?',
  [ID]: 'Apakah kamu yakin ingin memperbarui sopir tersebut?',
};

export const CHAT_ENABLED = {
  [EN]: 'Chat Enabled',
  [ID]: 'Aktifkan Chat',
};

export const SET_DRIVER_LANGUAGES = {
  [EN]: 'Set Driver Languages',
  [ID]: 'Tentukan Bahasa Sopir',
};

export const DRIVER_LOCATION = {
  [EN]: 'Driver Location',
  [ID]: 'Lokasi Sopir',
};

export const DRIVER_CURRENT_LOCATION = {
  [EN]: 'Driver Current Location',
  [ID]: 'Lokasi Sopir Saat Ini',
};

export const DRIVER_WILL_ARRIVE_IN = {
  [EN]: 'Will Arrive In',
  [ID]: 'Akan Tiba Dalam',
};

export const DRIVER_ETA_AT_PICKUP_POINT = {
  [EN]: 'Estimated Arrival at Pick-up Point',
  [ID]: 'Estimasi Tiba di Lokasi Jemput',
};

export const REFRESH_DRIVER_LOCATION = {
  [EN]: 'Refresh Driver’s Location',
  [ID]: 'Refresh Lokasi Sopir',
};
